import React, { useState } from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import { remapStatus } from 'legacy';
import { API } from 'htcore';
import { BOOKING_STATUS, isFinalBookingStatus } from 'htcore/enum/booking-status-enum';
import apiMethods from 'core/methods';
import { Descriptions, Button } from 'antd';
import ChangeHotelConfirmationCode from './booking-parts/change-hotel-confirmation-code';
import BookingAutoCancellationButton from './booking-parts/booking-auto-cancellation-button';
import $auth from 'stores/auth';
import BookingStatus from './booking-status';
import BookingInfoTabs from './booking-info-tabs';

const BookingConfirmationView = ({ isOffline, serviceCode, booking, reloadBooking }) => {
    const [isModalVisible, setModalVisibility] = useState(false);
    const details = booking.bookingDetails;

    const refreshStatus = () => {
        API.post({
            url: apiMethods.bookingRefreshStatus(booking.bookingId),
            success: () => {
                reloadBooking();
            },
        });
    };

    return (
        <>
            <Descriptions
                bordered
                layout="vertical"
                size="small"
                style={{ margin: '-20px 0 30px', position: 'relative' }}
            >
                <Descriptions.Item label="Booking Reference Number">
                    {booking.itineraryNumber && (
                        <div style={{ marginBottom: 10 }}>
                            Itinerary: <strong>{booking.itineraryNumber}</strong>
                        </div>
                    )}

                    <strong>{details.referenceCode}</strong>

                    {Boolean(details.agentReference) && (
                        <div style={{ marginTop: 10 }}>
                            Supplier Reference Code: <strong>{details.agentReference}</strong>
                        </div>
                    )}

                    <div style={{ marginTop: 10 }}>
                        Hotel Confirmation Code: <strong>{details.hotelConfirmationCode || '-'}</strong>
                        {$auth.permitted('BookingManagement') &&
                            ![
                                BOOKING_STATUS.Rejected,
                                BOOKING_STATUS.Discarded,
                                BOOKING_STATUS.Invalid,
                                BOOKING_STATUS.Completed,
                            ].includes(details.status) && (
                                <Button
                                    size="small"
                                    style={{ marginLeft: 10 }}
                                    onClick={() => setModalVisibility(true)}
                                >
                                    {details.hotelConfirmationCode ? 'Change' : 'Set'}
                                </Button>
                            )}
                    </div>

                    <div style={{ marginTop: 10 }}>
                        Agency Reference Code: <strong>{booking.agencyReferenceCode || '-'}</strong>
                    </div>
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                    <BookingStatus status={details.status} />
                    {!isFinalBookingStatus(details.status) && !serviceCode && (
                        <Button
                            size="small"
                            style={{ marginLeft: 20 }}
                            onClick={refreshStatus}
                            icon={<ReloadOutlined />}
                        />
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="Payment">
                    Status: <strong>{remapStatus(booking.paymentStatus)}</strong>
                    <br />
                    Method: <strong>{remapStatus(booking.paymentMethod)}</strong>
                    {!isOffline && !serviceCode && (
                        <BookingAutoCancellationButton booking={booking} reloadBooking={reloadBooking} />
                    )}
                </Descriptions.Item>
            </Descriptions>

            <BookingInfoTabs isService={!!serviceCode} booking={booking} />

            {isModalVisible && (
                <ChangeHotelConfirmationCode
                    booking={booking}
                    serviceCode={serviceCode}
                    visible
                    onClose={() => {
                        setModalVisibility(false);
                    }}
                    onFinish={() => {
                        reloadBooking();
                    }}
                />
            )}
        </>
    );
};

export default BookingConfirmationView;
