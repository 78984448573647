import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from 'htcore';
import apiMappingMethods from 'core/mapping-methods';
import { Form } from 'antd';
import RunMappingForm from './run-mapping-form';

const mapDataToPayload = (values) => {
    const supplierProperties = values.suppliers.reduce((acc, { supplierId, accommodationIds }) => {
        acc[supplierId] = accommodationIds ? accommodationIds.split('\n') : [];
        return acc;
    }, {});

    return {
        tasks: values.types.map((type) => ({
            type,
            options: {
                supplierProperties,
                mappingMode: type === 'Mapping' ? values.mappingType : 'None',
            },
        })),
    };
};

const RunMapping = () => {
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();
    const navigate = useNavigate();

    const onSubmit = (values) => {
        setLoading(true);

        API.post({
            mapper: apiMappingMethods.runMapping,
            body: mapDataToPayload(values),
            success: () => navigate('./jobs-queue'),
            after: () => setLoading(false),
        });
    };

    return (
        <Form
            form={form}
            onFinish={onSubmit}
            layout="vertical"
            initialValues={{ suppliers: [{}], mappingType: 'Giata' }}
        >
            <RunMappingForm loading={loading} form={form} />
        </Form>
    );
};

export default RunMapping;
