import React, { useEffect } from 'react';
import { Col, Form, Input, Row } from 'antd';
import { API } from 'htcore';
import shimabaraMethods from 'core/shimabara-methods';

const ExistedMappingForm = ({ form }) => {
    const supplierCode = Form.useWatch('supplierCode', form);

    const clearPreviewFields = () => {
        form.setFieldValue('hotelName', null);
        form.setFieldValue('address', null);
    };

    const getHotelData = (accommodationId) => {
        if (!accommodationId || !supplierCode) {
            clearPreviewFields();
            return;
        }

        API.get({
            shimabara: shimabaraMethods.supplierProperties(supplierCode, accommodationId),
            ignoreErrors: true,
            success: (result) => {
                form.setFieldValue('hotelName', result.name);
                form.setFieldValue('address', result.address);
            },
            error: () => clearPreviewFields(),
        });
    };

    const onChangeAccommodationId = (event) => {
        const { value } = event.target;

        getHotelData(value);
    };

    useEffect(() => {
        const accommodationId = form.getFieldValue('supplierPropertyId');
        getHotelData(accommodationId);
    }, [supplierCode]);

    return (
        <>
            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item
                        name="supplierPropertyId"
                        label="Supplier Accommodation ID"
                        rules={[{ required: true, message: 'Please Enter Accommodation ID' }]}
                    >
                        <Input
                            maxLength={128}
                            placeholder="Supplier Accommodation ID"
                            onChange={onChangeAccommodationId}
                        />
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item name="hotelName" label="Hotel Name">
                        <Input disabled placeholder="Hotel Name" />
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item name="address" label="Address">
                        <Input disabled placeholder="Address" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item
                        name="htAccommodationId"
                        label="Happy Travel Accommodation ID"
                        rules={[{ required: true, message: 'Please Enter Accommodation ID' }]}
                    >
                        <Input maxLength={128} placeholder="Happy Travel Accommodation ID" />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};

export default ExistedMappingForm;
