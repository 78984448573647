import React, { useEffect } from 'react';
import BigNumber from 'bignumber.js';
import apiMethods from 'core/methods';
import { Form, Typography, Row, InputNumber, Tooltip, Space } from 'antd';
import { InfoCircle } from 'iconsax-react';
import CurrencySelector from 'components/selectors/currency-selector';
import Table from 'components/table';
import { toFixedDownString } from './use-currencies';
import { getRoomsMarkupsAmount } from './use-offline-booking-data';

const { Title } = Typography;
const { useWatch } = Form;

const formatNumber = (number) => {
    if (isNaN(number)) return number;
    let strNumber = number;

    if (strNumber.includes('.')) {
        let [integerPart, decimalPart] = strNumber.split('.');
        if (decimalPart.length < 2) {
            decimalPart = decimalPart.padEnd(2, '0');
        }
        return `${integerPart}.${decimalPart}`;
    } else {
        return `${strNumber}.00`;
    }
};

export const getTotalPrice = (rooms = []) =>
    rooms.reduce((acc, cur) => acc.plus(cur?.amount || 0), new BigNumber(0));

const columns = (creation, totalPrice, onChangeMarkup, onChangeMarkupAmount) => [
    {
        header: 'Supplier Cost (Total)',
        cell: (cell) => `${totalPrice || cell?.total} ${cell?.supplierCurrency || ''}`,
    },
    {
        header: 'Supplier Currency to USD',
        cell: 'supplierUsdRate',
    },
    {
        header: (
            <span>
                Customer Currency<span style={{ color: '#ff4d4f' }}> *</span>
            </span>
        ),
        cell: () => (
            <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                    const agencyId = getFieldValue('agencyId');
                    const surrogateAgencyId = getFieldValue('surrogateAgencyId');

                    return (
                        <Form.Item name="customerCurrency" rules={[{ required: true }]}>
                            <CurrencySelector
                                url={
                                    surrogateAgencyId || agencyId
                                        ? apiMethods.agencyAvailableCurrencies(surrogateAgencyId || agencyId)
                                        : null
                                }
                                disabled={!creation || !!getFieldValue('selectedItineraryNumber')}
                            />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        ),
    },
    {
        header: 'Customer Currency to USD',
        cell: 'customerUsdRate',
    },
    {
        header: 'Supplier Cost (Total) in USD',
        cell: (cell) => `USD ${cell?.totalInUsd}`,
    },
    {
        header: 'Markup Amount',
        cell: (cell) => (
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                    <Form.Item name="fixedMarkupAmount">
                        <InputNumber
                            placeholder="0"
                            min={0}
                            addonBefore={getFieldValue('customerCurrency')}
                            onChange={() => onChangeMarkupAmount(cell)}
                        />
                    </Form.Item>
                )}
            </Form.Item>
        ),
    },
    {
        header: 'Markup Percent',
        cell: (cell) => (
            <Form.Item name="markupValue">
                <InputNumber
                    placeholder="0"
                    min={0}
                    max={100}
                    addonAfter="%"
                    onChange={() => onChangeMarkup(cell)}
                />
            </Form.Item>
        ),
    },
    {
        header: 'Supplier to Customer Exch.Rate  (On Total Cost)',
        cell: 'exchangeRate',
    },
    {
        header: 'Selling Price (Total) (Customer CUR)',
        cell: (cell) => formatNumber(cell.totalInCustomerCurrency),
    },
];

const RateDetails = ({ creation, totalPrice, exchangeRate, form, getCurrenciesData }) => {
    const customerCurrency = useWatch('customerCurrency', form);
    const supplierCurrency = useWatch('supplierCurrency', form);
    const fixedMarkupAmount = useWatch('fixedMarkupAmount', form);
    const rooms = useWatch('rooms', form);

    const { getFieldValue, setFieldValue } = form;

    const getFullPrice = (values) => {
        const roomsMarkupsAmount = getRoomsMarkupsAmount(
            values.map((item) => ({ ...item, amount: item.amount || item.price || 0 })),
            fixedMarkupAmount || 0
        );

        return toFixedDownString(
            values.reduce((total, { amount, price }, index) => {
                const { totalWithoutMarkup } = getCurrenciesData(amount || price || 0);

                const roomTotal = new BigNumber(toFixedDownString(totalWithoutMarkup)).plus(
                    roomsMarkupsAmount[index].toNumber()
                );
                return total.plus(roomTotal);
            }, new BigNumber(0))
        );
    };

    const createData = () => {
        const total = getTotalPrice(getFieldValue('rooms'));

        const data = getCurrenciesData(totalPrice ? totalPrice : total);

        return {
            ...data,
            total,
            supplierCurrency,
            totalInCustomerCurrency: getFullPrice(rooms || []),
        };
    };

    const changeValueDecimalPlaces = (name) => {
        const value = getFieldValue(name);
        setFieldValue(name, value ? toFixedDownString(Number(value)) : value);

        return value;
    };

    const onChangeMarkup = ({ totalWithoutMarkup }) => {
        const value = changeValueDecimalPlaces('markupValue');

        if (Number(value) && Number(totalWithoutMarkup)) {
            const percent = new BigNumber(value).dividedBy(100);

            const markupAmountValue = new BigNumber(totalWithoutMarkup || 0).multipliedBy(percent);

            setFieldValue('fixedMarkupAmount', toFixedDownString(markupAmountValue || 0));
        } else setFieldValue('fixedMarkupAmount', null);
    };

    const onChangeMarkupAmount = ({ totalWithoutMarkup }) => {
        const value = changeValueDecimalPlaces('fixedMarkupAmount');

        if (Number(value) && Number(totalWithoutMarkup)) {
            const amount = new BigNumber(value).dividedBy(totalWithoutMarkup);

            const markupPercentValue = amount.multipliedBy(100);

            setFieldValue('markupValue', toFixedDownString(markupPercentValue || 0));
        } else setFieldValue('markupValue', null);
    };

    useEffect(() => {
        if (!!Number(getFieldValue('fixedMarkupAmount'))) {
            onChangeMarkupAmount(createData());
        }
    }, [rooms, supplierCurrency, customerCurrency, exchangeRate]);

    return (
        <div>
            <Tooltip title="Please note that all conversion rates are calculated and applied based on the booking creation date">
                <Space style={{ alignItems: 'center' }}>
                    <Title level={4}>Rate Details</Title>
                    <InfoCircle size="20" color="#016FD0" variant="Bold" />
                </Space>
            </Tooltip>

            <Row size="large" style={{ marginBottom: 40 }} className="inputs-table">
                <Form.Item noStyle shouldUpdate>
                    <Table
                        style={{ width: '100%' }}
                        list={[createData()]}
                        columns={columns(creation, totalPrice, onChangeMarkup, onChangeMarkupAmount)}
                    />
                </Form.Item>
            </Row>
        </div>
    );
};

export default RateDetails;
