import React from 'react';
import { Col, Form, Input, Row } from 'antd';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import CountrySelector from 'components/selectors/country-selector';
import MappingLocalitySelector from './mapping-locality-selector';

const ExistedMappingForm = ({ form }) => {
    const clearPreviewFields = () => {
        form.setFieldValue('hotelName', null);
        form.setFieldValue('address', null);
    };

    const onChangeGiataId = (event) => {
        const { value } = event.target;

        form.validateFields(['supplierPropertyId']);

        if (!value) {
            clearPreviewFields();
            return;
        }

        API.get({
            shimabara: apiMethods.accommodationByGiataId(value),
            ignoreErrors: true,
            success: (result) => {
                form.setFieldValue('hotelName', result.name);
                form.setFieldValue('address', result.address);
            },
            error: () => clearPreviewFields(),
        });
    };

    const isValidIdsField = (value) => {
        const htAccommodationId = form.getFieldValue('supplierPropertyId');

        return value || htAccommodationId;
    };

    const isValidSupplierPropertyId = (value) => {
        const giataId = form.getFieldValue('giataId');

        return value || giataId;
    };

    return (
        <>
            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item noStyle shouldUpdate>
                        {({ validateFields }) => (
                            <Form.Item
                                name="supplierPropertyId"
                                label="Supplier Accommodation ID"
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            isValidSupplierPropertyId(value)
                                                ? Promise.resolve()
                                                : Promise.reject(
                                                      new Error('Please select Giata ID or Accommodation ID')
                                                  ),
                                    },
                                ]}
                            >
                                <Input
                                    maxLength={128}
                                    placeholder="Supplier Accommodation ID"
                                    onChange={() => validateFields(['giataId'])}
                                />
                            </Form.Item>
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item
                        name="giataId"
                        label="Giata ID"
                        rules={[
                            {
                                pattern: new RegExp(/^[0-9]+$/),
                                message: 'Only numbers allowed!',
                            },
                            {
                                validator: (_, value) =>
                                    isValidIdsField(value)
                                        ? Promise.resolve()
                                        : Promise.reject(
                                              new Error('Please select Giata ID or Accommodation ID')
                                          ),
                            },
                        ]}
                    >
                        <Input maxLength={24} placeholder="Giata ID" onChange={onChangeGiataId} />
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item name="hotelName" label="Hotel Name">
                        <Input disabled placeholder="Hotel Name" />
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item name="address" label="Address">
                        <Input disabled placeholder="Address" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item
                        name="countryCode"
                        label="Country"
                        rules={[{ required: true, message: 'Please Select Country' }]}
                    >
                        <CountrySelector
                            idName="code"
                            onChange={() => form.setFieldValue('localityHtId', null)}
                            placeholder="Country"
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue('countryCode') && (
                        <Row gutter={20}>
                            <Col span={6}>
                                <Form.Item name="localityHtId" label="Locality">
                                    <MappingLocalitySelector countryCode={getFieldValue('countryCode')} />
                                </Form.Item>
                            </Col>
                        </Row>
                    )
                }
            </Form.Item>
        </>
    );
};

export default ExistedMappingForm;
