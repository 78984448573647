import React, { useState, useEffect, useMemo } from 'react';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { notifications } from 'components/notification';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';
import ConfirmationSimpleModal from 'components/confirms/confirmation-simple-modal';
import { BOOKING_STATUS, PAYMENT_STATUS, PAYMENT_TYPE } from '../../../htcore/enum';

const CompletePaymentManuallyButton = ({ booking, loadBooking, isService, isOffline }) => {
    const [completePaymentManuallyModalVisible, setCompleteManuallyModalVisible] = useState(false);
    const [agency, setAgency] = useState(null);
    const { refCode } = useParams();

    const isThisCrazySurrogateType = booking.surrogateAgencyName && !booking.useOldSurrogateFlow;

    useEffect(() => {
        if (isThisCrazySurrogateType) {
            return;
        }
        API.get({
            url: apiMethods.agency(booking.agencyId),
            success: setAgency,
        });
    }, []);

    const requestUrl = useMemo(() => {
        if (isService) {
            return apiMethods.manuallyCompletePaymentOfflineService(booking.bookingId);
        }
        if (booking.paymentMethod === PAYMENT_TYPE.CreditCard) {
            return apiMethods.manuallyCompletePaymentCreditCard(booking.bookingId);
        }
        return apiMethods.manuallyCompletePayment(booking.bookingId);
    }, [booking, isService]);

    const bookingConfirmPayment = () => {
        API.post({
            url: requestUrl,
            success: () => {
                notifications('Success', 'success');
                setCompleteManuallyModalVisible(false);
                loadBooking();
            },
        });
    };

    if (booking.paymentStatus === PAYMENT_STATUS.NotPaid) {
        if (agency?.contractKind !== 'OfflineOrCreditCardPayments' && !isThisCrazySurrogateType) {
            return null;
        }

        if (isThisCrazySurrogateType && booking.paymentMethod !== PAYMENT_TYPE.Offline) {
            return null;
        }
    }

    if (booking.paymentStatus === PAYMENT_STATUS.Authorized) {
        if (
            booking.bookingDetails?.status !== BOOKING_STATUS.Confirmed ||
            booking.paymentMethod !== PAYMENT_TYPE.CreditCard ||
            isOffline
        ) {
            return null;
        }
    }

    return (
        <>
            <ConfirmationSimpleModal
                visible={completePaymentManuallyModalVisible}
                onCancel={() => setCompleteManuallyModalVisible(false)}
                headerText="Are you absolutely sure?"
                submitText="Confirm"
                onConfirm={bookingConfirmPayment}
            >
                This action <b>cannot</b> be undone. This will mark a payment for the booking <b>{refCode}</b>{' '}
                as <b>paid</b> in the system.
            </ConfirmationSimpleModal>
            <Button onClick={() => setCompleteManuallyModalVisible(true)}>Manually Confirm Payment</Button>
        </>
    );
};

export default CompletePaymentManuallyButton;
