import { date } from 'legacy';

export const serializeRequest = (values, top, skip) => {
    const body = {};

    if (values.deactivationReasons) {
        body.deactivationReasons = values.deactivationReasons;
    }

    if (values.ratings?.length) {
        body.ratings = values.ratings;
    }

    if (values.suppliers?.length) {
        body.suppliers = values.suppliers;
    }

    if (values.nameQuery) {
        body.nameQuery = values.nameQuery;
    }

    if (values.addressLineQuery) {
        body.addressLineQuery = values.addressLineQuery;
    }

    if (values.countryId) {
        body.countryIds = [values.countryId];
    }

    if (values.countryIds?.length) {
        body.countryIds = values.countryIds;
    }

    if (values.localityId) {
        body.localityIds = [values.localityId];
    }

    if (values.localityIds) {
        body.localityIds = values.localityIds;
    }

    if (values.isActive) {
        body.isActive = true;
    }

    if (values.hasDirectContract) {
        body.hasDirectContract = true;
    }

    if (values.considerRestrictions) {
        body.considerRestrictions = true;
    }

    if (values.rangeDates) {
        body.checkout = {
            fromDate: date.format.apiDateOnly(values.rangeDates[0]),
            toDate: date.format.apiDateOnly(values.rangeDates[1]),
        };
    }

    if (values.bookingStatuses) {
        body.bookingStatuses = values.bookingStatuses;
    }

    if (values.created) {
        body.created = {
            fromDate: date.format.apiDateOnly(values.created[0]),
            toDate: date.format.apiDateOnly(values.created[1]),
        };
    }

    if (values.sortField) {
        body.sortField = values.sortField;
    }

    if (top !== undefined) {
        body.top = top;
    }

    if (skip !== undefined) {
        body.skip = skip;
    }

    return body;
};
