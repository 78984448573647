import React, { useState } from 'react';
import { JSONTree } from 'react-json-tree';
import { Collapse, Menu, Row, Tag } from 'antd';
import { date } from 'legacy';
import { ArrowDown2, ArrowRight2 } from 'iconsax-react';

const { Panel } = Collapse;

const StatusColors = {
    Running: 'blue',
    Canceled: 'default',
    Failed: 'red',
    Finished: 'green',
};

const ExpandIcon = ({ isActive }) => (
    <div className="arrow-holder">
        <span style={{ paddingTop: 4 }}>
            {isActive ? <ArrowDown2 size={14} /> : <ArrowRight2 size={14} />}
        </span>
    </div>
);

const TaskGroup = ({ index, group }) => {
    const [selectedTab, setSelectedTab] = useState('0');

    const menuItems = group.tasks.map(({ type }, typeIndex) => ({ label: type, key: typeIndex }));

    return (
        <Collapse expandIcon={ExpandIcon}>
            <Panel
                key={index}
                style={{ backgroundColor: '#FAFAFA' }}
                header={
                    <Row align="middle" justify="space-between">
                        <div>
                            <Tag color={StatusColors[group.status] || 'default'}>{group.status}</Tag>
                            <span style={{ fontSize: 16, fontWeight: 600 }}>Task Group #{group.id}</span>
                        </div>
                        <span style={{ fontSize: 13 }}>
                            Run {group.creator ? `by ${group.creator}` : ''} at:{' '}
                            <span style={{ color: '#777777' }}>({date.format.e(group.created)})</span>
                        </span>
                    </Row>
                }
            >
                <Menu
                    mode="horizontal"
                    items={menuItems}
                    selectedKeys={selectedTab}
                    style={{ marginLeft: 0, padding: 0 }}
                    onSelect={({ key }) => setSelectedTab(key)}
                />
                <JSONTree
                    theme="monokai"
                    hideRoot
                    invertTheme
                    getItemString={() => null}
                    shouldExpandNode={([item]) => item === 'options' || item === 'supplierProperties'}
                    data={group.tasks[selectedTab]}
                />
            </Panel>
        </Collapse>
    );
};

export default TaskGroup;
