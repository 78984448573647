import React, { useEffect, useMemo, useState } from 'react';
import { API } from 'htcore';
import { EntitySelector } from 'common/entity-selector';
import apiMethods from 'core/methods';

const optionsGenerator = (list = []) =>
    list.map((item) => ({
        value: item.id,
        label: `${item.name} (${item.id}) ${item.isActive ? '' : ' (INACTIVE)'}`,
        isSurrogate: item.isSurrogate,
    }));

const AgencySelector = ({
    filter,
    onlyActiveAgencies,
    onlySurrogateAgencies,
    selectedAgencies,
    ...props
}) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            let newOptions = [];
            let skip = 0;
            while (skip === newOptions.length) {
                newOptions.push(
                    ...(await API.get({
                        url: apiMethods.agencies,
                        body: {
                            ...(filter
                                ? {
                                      $filter: filter,
                                  }
                                : {}),
                            $top: 100,
                            $skip: skip,
                        },
                    }))
                );
                skip += 100;
            }

            setOptions(newOptions);
            setLoading(false);
        })();
    }, []);

    const filteredOptions = useMemo(() => {
        let newOptions = options;

        if (onlyActiveAgencies) {
            newOptions = newOptions.filter(
                (option) => !option.isSurrogate || selectedAgencies?.includes(option.id)
            );
        }

        if (onlySurrogateAgencies) {
            newOptions = newOptions.filter((option) => option.isSurrogate);
        }

        newOptions.sort((a, b) => b.isActive - a.isActive);

        return optionsGenerator(newOptions);
    }, [options, onlyActiveAgencies, onlySurrogateAgencies, selectedAgencies]);

    return (
        <EntitySelector
            placeholder="Any Agency"
            maxTagCount={3}
            {...props}
            value={typeof props.value === 'string' ? parseInt(props.value) : props.value}
            options={filteredOptions}
            loading={loading}
        />
    );
};

export default AgencySelector;
