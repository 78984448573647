import React, { useState } from 'react';
import { Button } from 'antd';
import { API } from 'htcore';
import { PAYMENT_STATUS, PAYMENT_TYPE } from 'htcore/enum';
import { isFinalBookingStatus } from 'htcore/enum/booking-status-enum';
import apiMethods from 'core/methods';
import { notifications } from 'components/notification';
import ConfirmationSimpleModal from 'components/confirms/confirmation-simple-modal';
import $auth from 'stores/auth';

const BookingAutoCancellationButton = ({ booking, reloadBooking }) => {
    const [isModalVisible, setModalVisibility] = useState(false);
    const details = booking.bookingDetails;

    const onToggleStatus = () => {
        API.post({
            url: apiMethods.autoCancellationStatuses(),
            body: {
                [details.referenceCode]: !details.isAutoCancellationDisabled,
            },
            success: () => {
                notifications('Auto Cancellation Status Updated!', 'success');
                reloadBooking();
                setModalVisibility(false);
            },
        });
    };

    if (!$auth.permitted('BookingAutoCancellationManagement')) {
        return null;
    }

    if (
        booking.paymentMethod === PAYMENT_TYPE.CreditCard ||
        booking.paymentStatus === PAYMENT_STATUS.Invoiced
    ) {
        return null;
    }

    return (
        <>
            <div style={{ marginTop: 10 }}>
                {details.isAutoCancellationDisabled ? (
                    <>
                        <strong>
                            {' '}
                            <span
                                className="icon icon-failed"
                                style={{ float: 'left', margin: '15px 20px -10px 0' }}
                            />{' '}
                            Auto Cancellation Disabled
                        </strong>
                        <br />{' '}
                        {details.autoCancellationModifiedBy ? `by ${details.autoCancellationModifiedBy}` : ''}
                    </>
                ) : (
                    <>
                        Auto Cancellation Enabled
                        <br />
                        {details.autoCancellationModifiedBy ? `by ${details.autoCancellationModifiedBy}` : ''}
                    </>
                )}
                <br />
                {!isFinalBookingStatus(details.status) && (
                    <Button size="small" style={{ marginTop: 8 }} onClick={() => setModalVisibility(true)}>
                        {details.isAutoCancellationDisabled
                            ? 'Enable Auto Cancellation'
                            : 'Disable Auto Cancellation'}
                    </Button>
                )}
            </div>
            <ConfirmationSimpleModal
                visible={isModalVisible}
                onCancel={() => setModalVisibility(false)}
                headerText="Are you sure?"
                submitText="Approve"
                onConfirm={onToggleStatus}
            >
                {details.isAutoCancellationDisabled ? (
                    <>You are going to enable auto-cancellation for the booking,</>
                ) : (
                    <>You are going to cancel auto-cancellation for the booking,</>
                )}{' '}
                kindly ask you to approve your action
            </ConfirmationSimpleModal>
        </>
    );
};

export default BookingAutoCancellationButton;
